<template>
  <div class="like-btn-container">
    <div :class="['circle-bg', isClassEnbaled]"></div>
    <div v-if="showAddtoWatchlist">
      <svg @click.stop="watchListHander" id="watchlist_add" data-name="watchlist add" xmlns="http://www.w3.org/2000/svg" :class="isClassEnbaled" viewBox="0 0 48 48" fill="none" >
        <g filter="url(#filter0_b_792_2500)">
        <circle cx="24" cy="24" r="24" fill="white" fill-opacity="0.3"/>
        </g>
        <path d="M25.9456 20.7565H12.9726C12.2591 20.7565 11.6753 21.3403 11.6753 22.0538C11.6753 22.7673 12.2591 23.3511 12.9726 23.3511H25.9456C26.6591 23.3511 27.2429 22.7673 27.2429 22.0538C27.2429 21.3403 26.6591 20.7565 25.9456 20.7565ZM25.9456 15.5673H12.9726C12.2591 15.5673 11.6753 16.1511 11.6753 16.8646C11.6753 17.5781 12.2591 18.1619 12.9726 18.1619H25.9456C26.6591 18.1619 27.2429 17.5781 27.2429 16.8646C27.2429 16.1511 26.6591 15.5673 25.9456 15.5673ZM32.4321 25.9457V22.0538C32.4321 21.3403 31.8483 20.7565 31.1348 20.7565C30.4212 20.7565 29.8375 21.3403 29.8375 22.0538V25.9457H25.9456C25.232 25.9457 24.6483 26.5295 24.6483 27.243C24.6483 27.9565 25.232 28.5403 25.9456 28.5403H29.8375V32.4322C29.8375 33.1457 30.4212 33.7295 31.1348 33.7295C31.8483 33.7295 32.4321 33.1457 32.4321 32.4322V28.5403H36.3239C37.0375 28.5403 37.6212 27.9565 37.6212 27.243C37.6212 26.5295 37.0375 25.9457 36.3239 25.9457H32.4321ZM12.9726 28.5403H20.7564C21.4699 28.5403 22.0537 27.9565 22.0537 27.243C22.0537 26.5295 21.4699 25.9457 20.7564 25.9457H12.9726C12.2591 25.9457 11.6753 26.5295 11.6753 27.243C11.6753 27.9565 12.2591 28.5403 12.9726 28.5403Z" fill="white"/>
      </svg>
    </div>
    <div v-if="!showAddtoWatchlist">
        <svg @click.stop="watchListHander" id="watchlist_add" data-name="watchlist add" xmlns="http://www.w3.org/2000/svg" :class="isClassEnbaled" viewBox="0 0 48 48" fill="none" >
        <g filter="url(#filter0_b_792_2500)">
        <circle cx="24" cy="24" r="24" fill="white" fill-opacity="0.3"/>
        </g>
        <path d="M12.5 24C12.5 22.8954 13.3954 22 14.5 22H33.5C34.6046 22 35.5 22.8954 35.5 24C35.5 25.1046 34.6046 26 33.5 26H14.5C13.3954 26 12.5 25.1046 12.5 24Z" fill="#D9D9D9"/>
    <!-- <path d="M25.9456 20.7565H12.9726C12.2591 20.7565 11.6753 21.3403 11.6753 22.0538C11.6753 22.7673 12.2591 23.3511 12.9726 23.3511H25.9456C26.6591 23.3511 27.2429 22.7673 27.2429 22.0538C27.2429 21.3403 26.6591 20.7565 25.9456 20.7565ZM25.9456 15.5673H12.9726C12.2591 15.5673 11.6753 16.1511 11.6753 16.8646C11.6753 17.5781 12.2591 18.1619 12.9726 18.1619H25.9456C26.6591 18.1619 27.2429 17.5781 27.2429 16.8646C27.2429 16.1511 26.6591 15.5673 25.9456 15.5673ZM32.4321 25.9457V22.0538C32.4321 21.3403 31.8483 20.7565 31.1348 20.7565C30.4212 20.7565 29.8375 21.3403 29.8375 22.0538V25.9457H25.9456C25.232 25.9457 24.6483 26.5295 24.6483 27.243C24.6483 27.9565 25.232 28.5403 25.9456 28.5403H29.8375V32.4322C29.8375 33.1457 30.4212 33.7295 31.1348 33.7295C31.8483 33.7295 32.4321 33.1457 32.4321 32.4322V28.5403H36.3239C37.0375 28.5403 37.6212 27.9565 37.6212 27.243C37.6212 26.5295 37.0375 25.9457 36.3239 25.9457H32.4321ZM12.9726 28.5403H20.7564C21.4699 28.5403 22.0537 27.9565 22.0537 27.243C22.0537 26.5295 21.4699 25.9457 20.7564 25.9457H12.9726C12.2591 25.9457 11.6753 26.5295 11.6753 27.243C11.6753 27.9565 12.2591 28.5403 12.9726 28.5403Z" fill="white"/> -->
        </svg>
    </div>
  </div>
 </template>
 <script>
 import { mapGetters } from "vuex";
 import { eventBus } from "@/eventBus";
 import firebase from "firebase/app";
 import "firebase/database";
 import FirebaseActions from "@/mixins/FirebaseActions";
 import googleAnalytics from "@/mixins/googleAnalytics.js";
 import { _projectName } from "@/provider-config.js";
 import { showSnackBar } from '../../utilities';
 export default {
   props: {
     content: {
       type: Object,
     },
     width: {
       type: String,
     },
     height: {
       type: String,
     },
     screen: {
       type: Object,
     },
     isSmall: {
      type: Boolean
     }
   },
   data() {
     return {
       showAddtoWatchlist: true,
       action: null,
       fillColor: null,
       colorCodeActive: '#FECA35',
       colorCodeDeactive: '#FFFFFF',
       BackCodeActive: '#FECA35',
       BackCodeDeactive: null,
       watchlistFlag: false,
       stokeColor: false,
       isClassEnbaled: 'changeSvglg'
 
     };
   },
   watch: {
     content() {
       this.fetchButtonState();
     },
     subscriberid() {
       if (this.subscriberid) {
         this.fetchButtonState();
       }
     },
   },
   computed: {
     ...mapGetters(["subscriberid", "profileid"]),
   },
   mounted () {
     this.stokeColor = localStorage.getItem('theme') == 'theme-light' ? true : false
     this.isClassEnbaled  = this.isSmall ? 'changeSvgsm' :'changeSvglg'
   },
   created() {
     this.fetchButtonState();
     this.fillColor =
       _projectName === "VLIVE"
         ? "#FF9600"
         : _projectName === "NET5"
         ? "#FF9600"
         : _projectName === "NAMMAFLIX"
         ? "#ffffff"
         : _projectName === "Noor Play"
         ? "#ec1c65"
         : "";
   },
   methods: {
    
     watchListHander () {
       if (this.subscriberid) {
        this.watchlistFlag = !this.watchlistFlag;
         if (this.showAddtoWatchlist) {
           this.addToWatchList()
           showSnackBar(this.$t('Added to watchlist'))
 
         } else {
           showSnackBar(this.$t('removed from watchlist'))
 
           this.removeFromWatchlist()
         } 
       } else {
         let payload = {
           state: true,
           formType: "lookup",
         };
         eventBus.$emit("authPopup", payload);
       }
     },
     fetchButtonState() {
       if (!this.subscriberid) {
         this.showAddtoWatchlist = true;
         return;
       }
       if (!this.content) return;
 
       let content = this.content;
 
       let path = `/subscriber/${this.subscriberid}/${this.profileid}/content/`;
 
       if ("seriesid" in content && content?.seriesid) {
         // let seriesid = content.seriesid;
         path += `${content?.objectid}/episodes/${content?.objectid}`;
       } else {
         path += `${content?.objectid}`;
       }
       firebase
         .database()
         .ref(path)
         .on("value", (snapshot) => {
           let response = snapshot.val();
           //   console.log("snapshot", snapshot.val());
           if (response && response.inwatchlist == true) {
             this.showAddtoWatchlist = false;
             this.action = "REMOVE";
           } else if (response && response.inwatchlist == false) {
             this.showAddtoWatchlist = true;
             this.action = "ADD";
           } else {
             this.showAddtoWatchlist = true;
             this.action = "ADD";
           }
           //   console.log("watchlist action", this.action);
         });
     },
     addToWatchList() {
       if (this.subscriberid) {
         this.showAddtoWatchlist = false;
         // this.content.orientation = this.screen.displayType;
         this.updateContentWatchlist(this.content, true);
         // if (this.action == "ADD") {
         //   console.log("update with add");
         //   this.updateContentWatchlist(this.content, true);
         // } else if (this.action == "REMOVE") {
         //   console.log("update with remove");
         //   this.updateContentWatchlist(this.content, false);
         // }
 
         //logging watchlist google analytics event.
         let GA = {
           action_name: "Add to watchlist",
         };
         this.userActionEvent(GA);
       } else {
         let payload = {
           state: true,
           formType: "lookup",
         };
         eventBus.$emit("authPopup", payload);
       }
     },
     removeFromWatchlist() {
       if (this.subscriberid) {
         this.showAddtoWatchlist = true;
         this.updateContentWatchlist(this.content, false);
 
         //logging watchlist google analytics event.
         let GA = {
           action_name: "Remove from watchlist",
         };
         this.userActionEvent(GA);
       }
     },
   },
   mixins: [FirebaseActions, googleAnalytics],
 };
 </script>
 
 
 <style lang="scss">
 
 .like-btn-container {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.circle-bg {
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  border-radius: 50%;
  transition: background-color 0.3s;
  z-index: -1;
  pointer-events: none;
}

.like-btn-container:hover .circle-bg {
  // background-color: #fff; 
}

.like-btn-container:hover svg path {
  fill: #ff0000 !important;
}
 .changeSvglg {
  height: 48px !important;
  width: 48px !important;
 }
 .changeSvgsm {
  height: 32px !important;
  width: 32px !important;
 }
 </style>